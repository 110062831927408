<template>
  <div class="row">
    <div class="col text-uppercase text-center countdown">
      Time Remaining
      <div class="d-flex justify-content-center text-center w-100 countdown-time">
        <div>
          {{ day }}
          <small class="d-block px-2">DAYS</small>
        </div>
        <div class="colon">:</div>
        <div>
          {{ hour }}
          <small class="d-block px-2">HOURS</small>
        </div>
        <div class="colon">:</div>
        <div>
          {{ min }}
          <small class="d-block px-2">MINUTES</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    endDate: {
      type: Date,
      default() {
        return new Date();
      },
    },
    negative: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      now: new Date(),
      timer: null,
    };
  },
  computed: {
    day() {
      let d = Math.trunc((this.endDate - this.now) / (1000 * 3600 * 24));
      return d > 0 ? d : 0;
    },
    hour() {
      let h = Math.trunc((this.endDate - this.now) / (1000 * 3600)) % 24;
      return h > 9 ? h : '0' + h;
    },
    min() {
      let m = Math.trunc((this.endDate - this.now) / (1000 * 60)) % 60;
      return m > 9 ? m : '0' + m;
    },
  },
  watch: {
    endDate: {
      immediate: true,
      handler(newVal) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.now = new Date();
          if (this.negative) return;
          if (this.now > newVal) {
            this.now = newVal;
            this.$emit('endTime');
            clearInterval(this.timer);
          }
        }, 1000);
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="scss">
  .countdown-time{
    font-size: 2.5em;
    font-weight: bold;
    small{
      font-size: .35em;
    }
    .colon{
      min-width:20px;
      text-align: center;
    }
  }
</style>
